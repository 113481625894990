import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
import {getList} from './api'
import { orderPass, queryShareFlag, sysconfig } from "@/views/order/channelExamine/api";
import { getDeliveryAmountFind } from "@/views/stockCenter/adjustmentApply/allot/api";
moment.locale("zh-cn");
export default {

  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/channelOrder/orderList",
          name: "orderList",
          title: "销售订单（审核出库）"
        },
        {
          path: "/channelOrder/quickAudit",
          name: "quickAudit",
          title: "快速审核"
        }
      ],
      bg: bg,

      page: {
        pageNo: 1,
        pageSize: 5
      },
      tableList:[],
      columns: [
        {
          label: '仓库',
          prop: 'gicWarehouseName',
          width: 120,
          ellipsis: true
        },
        {
          label: '产品型号',
          prop: 'model',
          width: 180,
          ellipsis: true
        },
        {
          label: '质量等级',
          prop: 'invStatusName',
          width: 90,
          ellipsis: true
        },
        {
          label: '补差类型',
          prop: 'invStatusTypeName',
          width: 90,
          ellipsis: true

        },
        {
          label: '定价组',
          prop: 'priceGroupName',
          width: 130,
          ellipsis: true
        },
        {
          label: '物料号',
          prop: 'material',
          width: 130,
          ellipsis: true
        },
        {
          label: '库存价格(元)',
          prop: 'price',
          width: 140,
        },
        {
          label: '实际库存',
          prop: 'totalBig',
          width: 100,
          ellipsis: true
        },
        {
          label: '预留数量',
          prop: 'qty',
          key:'222',
          width: 120,
          ellipsis: true
        },
        // {
        //   label: '入库时间',
        //   prop: 'inInvDate',
        //   width: 130,
        //   ellipsis: true
        // },
        {
          label: '可用数量',
          prop: 'bigQty',
          width: 120,
          ellipsis: true
        },
        {
          label: '本次出库数量',
          prop: 'qty1',
          // scopedSlots: {
          //   customRender: "count"
          // }
          slot:'count',
          width: 180,
          fixed: 'right',
        },
      ],
      sendObject: [],
      orderDetial:[],
      NeworderDetail:[],
      loadings: false,//loading效果
      loadMores: false,//查看更多
      noMore: false,//没有更多控制
      nolistImg: require("@/assets/order/noList.png"),
      NoDate: false,// 没有数据控制
      pageLoading: false,// 页面加载loading
      checked: false,// 全选按钮loading
      allLoading: false,// 批量出库按钮loading
      tableSearch:'',
      onluyOneMessage:false,
      shareTypezong:'',
      messageInfo:'',
      showTIP:false,//
      smallLoanSwitch:false,// 小贷总开关

    };
  },
  mounted() {
    this.getLoanSwitch()
    this.getisKQ()
    this.getListDeatil()

  },
  methods: {
    gotoCK(){
      this.$router.push({
        path:"/stockCenter/myStock"
      });
    },
    getLoanSwitch() {
      sysconfig({key:'QD_OPEN_SMALL_LOANS'}).then(res => {
        this.smallLoanSwitch = res.data.data == 'Y' ? true : false
      })
    },
    // 判断当前账户是否签约贷款合同
    getisKQ(){
      let data = {
        serviceCode:'queryDeliveryAmountFind',
        mdmCode:this.$store.state.user.userInfo.customer.customerCode
      }
      getDeliveryAmountFind(data).then(res=>{
        if(res.data.success == 'true'){
          this.showTIP = res.data.msg=='true'?true:false
          this.$forceUpdate()
        }else {
          this.showTIP = false
        }
      })
    },
    // 更改状态
    changeStatus(item){
      item.cherkStatus = !item.cherkStatus
      this.$forceUpdate()
    },
    //  提交
    orderSubmit(firstItem) {
      if(firstItem.openCloseBtn && !firstItem.cherkStatus) {
        this.$message.warning('请确认质物明细!')
        return
      }
      let ckNum = 0
      firstItem.salesOrderItem.forEach((item,index)=>{
        if(item.bencichuku > 0 ) {
          ckNum+=1
        }
      })
      if(ckNum <= 0) {
        this.$message.warning('请先选择仓库');
        return;
      } else {
            let realValue = firstItem.salesOrderItem;
            let submitArray = [],submitData={}
            let outThis = 0
            for(let i = 0;i< realValue.length;i++) {
                if(realValue[i].bencichuku == 0) {
                  this.$message.warning('第' + Number(i + 1) + '个商品仓库数据不能为0');
                  return;
                }
               if(realValue[i]) {
                 for (let z = 0; z < realValue[i].inventoryList.length; z++) {
                   outThis  += realValue[i].inventoryList[z].qty1;
                   // cherk 为true  并且数量大于0  传给后台
                 // && realValue[i].inventoryList[z].qty1 > 0
                   if(realValue[i].inventoryList[z].cherk == true ) {
                     submitData = {
                        itemId:realValue[i].itemId,
                         materialCode:realValue[i].inventoryList[z].material,
                         productCode: realValue[i].inventoryList[z].productCode,
                         invStatus: realValue[i].inventoryList[z].invStatusId,
                         invStatusType: realValue[i].inventoryList[z].invStatusType,
                         gicOutWarehouse: realValue[i].inventoryList[z].gicWareHouse,
                         backnowledgedQty: realValue[i].inventoryList[z].qty1
                     }
                     submitArray.push(submitData);
                   }
                   // if (outThis > realValue[i].backnowledgedQty) {
                   //   this.$message.warning('第' + Number(i + 1) + '个商品出库数量大于商品该出库数量')
                   //   return;
                   // }
                 }
               }
            }
          // if (this.daichuku < this.chukuTotal) {
          //   this.$message.warning('出库数量不得大于待出库数量');
          //   return;
          // }
        // 订单出库
        let data = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          userAccount: this.$store.state.user.userInfo.account.account,
          orderItem: submitArray,
          salesOrderId: firstItem.id,
          dataSource: 'xtw_pc'
        }
        firstItem.shenheIsLoading = true;
        this.$forceUpdate()
        orderPass(data).then(res => {
          this.isLoading = false;
          var realres = JSON.parse(res.data);
          if (realres.code == '1') {
            // this.$message.warning(realres.msg);
            this.messageInfo = realres.msg
            this.onluyOneMessage = true
            //暂时不用刷新  需要再说
            // this.timer = setTimeout(() => {  //设置延迟执行
            //   //  刷新页面
            //   this.getListDeatil();
            // }, 5000);
            firstItem.shenheIsLoading = false;
            this.$forceUpdate()
          } else if (realres.code == '0') {
            this.$message.success('审核成功')
            firstItem.shenheIsLoading = false;
            this.$forceUpdate()
            this.getListDeatil()
            localStorage.setItem("saleOrderListTime", new Date().getTime());
          }
        }).finally(() => {
          firstItem.shenheIsLoading = false;
          this.$forceUpdate()
        })
      }

    },
    //快速满足
    fastWahouse(firstItema,twoItem,type,firstIndex) {
      // 如果类型是70 提示不展示 如果是20并且总开关开着 就展示提示
      if(type == '20' && this.showTIP &&  this.smallLoanSwitch ) {
        this.orderDetial[firstIndex].openCloseBtn = true
        this.orderDetial[firstIndex].cherkStatus = true
      } else {
        this.orderDetial[firstIndex].openCloseBtn = false
        this.orderDetial[firstIndex].cherkStatus = false
      }


     let data = {
       "terms": {
         "status": "po_submitted",
         'gicWarehouseType':type,
         'documentNum':firstItema.documentNum
       },
       "page":{
         pageSize:5,
         pageNo: 1
       }
     }
      getList(data).then(res=>{
        let realDate = JSON.parse(res.data);
        // 如果有数据展示
        if(realDate.code == 0) {
          if(res && res.data){
            let realDate = JSON.parse(res.data);
            if(realDate.data.length > 0) {
              realDate.data.forEach(firstItem=>{
                if(firstItem.id == firstItema.id) {
                  // 出库面积
                  firstItem.chukuVolume = 0
                  // 待出库数量
                  firstItem.daichuku = 0
                  firstItem.chukuVolume = 0
                  // 出库总数
                  firstItem.chukuTotal = 0
                  firstItem.salesOrderItem.forEach(item=>{
                    if(item.itemId == twoItem.itemId ) {
                      if(item.inventoryList.length <= 0 ) {
                        this.messageInfo = '暂无符合条件的库存'
                        this.onluyOneMessage = true
                      }
                      twoItem.inventoryList = JSON.parse(JSON.stringify(item.inventoryList))
                      twoItem.searchLength = twoItem.inventoryList.length
                      twoItem.bencichuku = 0
                      twoItem.keyong = 0
                      twoItem.inventoryList.forEach(every=>{
                        every.qty1 = every.outQty
                        if( every.qty1 > 0) {
                          every.cherk = true
                        } else {
                          every.cherk = false
                        }
                        twoItem.bencichuku += Number(every.qty1)
                        twoItem.keyong += Number(every.bigQty)
                      })
                    }
                    let bb  = 0
                    let aa = 0
                    let cc = 0
                    firstItema.salesOrderItem.forEach(item=>{
                      aa += Number(item.bencichuku)
                      bb += Number(item.backnowledgedQty )
                      cc += (Number(item.bencichuku)*Number(item.volume))
                    })
                    firstItema.chukuTotal = aa
                    firstItema.daichuku = bb
                    firstItema.chukuVolume = this.$util.getFloat(cc, 3)
                    this.$forceUpdate()
                  })
                }
              })
            }
          }
        }
        // 弹出错误信息
        if(realDate.code ==1){
          twoItem.inventoryList = []
          twoItem.searchLength = 0
          twoItem.bencichuku = 0
          let bb  = 0
          let aa = 0
          let cc = 0
          firstItema.salesOrderItem.forEach(item=>{
            aa += Number(item.bencichuku)
            bb += Number(item.backnowledgedQty )
            cc += (Number(item.bencichuku)*Number(item.volume))
          })
          firstItema.chukuTotal = aa
          firstItema.daichuku = bb
          firstItema.chukuVolume = this.$util.getFloat(cc, 3)
          this.$forceUpdate()
          this.messageInfo = realDate.msg
          this.onluyOneMessage = true
        }

      })

    },
    // 清除选择
    clearChoose(item, index) {
        item.salesOrderItem.forEach(one=>{
          one.inventoryList.forEach(two=>{
            two.cherk = false
          })
        })
      item.checkAll = false
      // 清空筛选以后重新赋值选择项
      item.salesOrderItem = JSON.parse(JSON.stringify(this.NeworderDetail[index].salesOrderItem))
      item.salesOrderItem.forEach(item=>{
        item.searchLength = item.inventoryList.length

      })
      this.$forceUpdate()

    },
    // 金查看已选
    onlyShowCheckedChange(item, index, e) {
      item.checkAll = e.target.checked
      this.$forceUpdate()
      if( item.checkAll  == true) {
        item.salesOrderItem.forEach(first=>{
          first.tableList = []
          first.inventoryList.forEach(twoItem=>{
            if(twoItem.cherk == true) {
              first.tableList.push(twoItem)
            }
            first.inventoryList = first.tableList
            first.searchLength = first.inventoryList.length
          })
        })
      }
      if(item.checkAll  == false){
        item.salesOrderItem = JSON.parse(JSON.stringify(this.NeworderDetail[index].salesOrderItem))
      }
      this.$forceUpdate()
    },
    // 搜索框清空
    handleinput(firstIndex,index, e) {
      let tota = 0
      this.orderDetial[firstIndex].salesOrderItem[index].inventoryList = this.NeworderDetail[firstIndex].salesOrderItem[index].inventoryList;
      for (var i = 0; i < this.NeworderDetail[firstIndex].salesOrderItem[index].inventoryList.length; i++) {
        tota += Number(this.NeworderDetail[firstIndex].salesOrderItem[index].inventoryList[i].bigQty)
      }
      this.orderDetial[firstIndex].salesOrderItem[index].kechuku = tota;
      this.orderDetial[firstIndex].salesOrderItem[index].searchLength = this.orderDetial[firstIndex].salesOrderItem[index].inventoryList.length;
    },
    // 搜索仓库
    tableSearchClick(firstIndex,index) {
      var newarray = [];
      for (let item of this.orderDetial[firstIndex].salesOrderItem[index].inventoryList) {
        let indexok = item.gicWarehouseName.indexOf(this.tableSearch)
        if (indexok != -1) {
          newarray.push(item);
        }
      }
      let tota = 0
      for (var i = 0; i < newarray.length; i++) {
        tota += Number(newarray[i].bigQty)
      }
      this.orderDetial[firstIndex].salesOrderItem[index].kechuku = tota;
      this.orderDetial[firstIndex].salesOrderItem[index].searchLength = newarray.length;
      this.orderDetial[firstIndex].salesOrderItem[index].inventoryList = newarray;
    },
      //出库数量改变
    inputNumberChange(inventoryList,row,index,firstIndex,twoIndex,e) {
      this.$forceUpdate()
      // firstIndex：第一层index
      // twoIndex：第二层indx
      // index：表格数据的index
      // e:更改的数据var totalIndex = record.index;
      
        inventoryList[index].qty1 = e
        if(inventoryList[index].qty1 > 0) {
          inventoryList[index].cherk = true
        } else {
          inventoryList[index].cherk = false
        }
        let benci = 0
        inventoryList.forEach(item=>{
          benci +=  Number(item.qty1)
        })
      let bencichukua = 0
      let tijiall =0
      this.orderDetial[firstIndex].salesOrderItem[twoIndex].bencichuku = benci
      this.orderDetial[firstIndex].salesOrderItem.forEach(item=>{
        bencichukua+= item.bencichuku
        tijiall += (Number(item.volume)*item.bencichuku)
      })
      this.orderDetial[firstIndex].chukuTotal =bencichukua
      this.orderDetial[firstIndex].chukuVolume = this.$util.getFloat(tijiall, 3)
      let cherkNum = 0
      if( this.orderDetial[firstIndex].salesOrderItem.length > 0 ) {
        this.orderDetial[firstIndex].salesOrderItem.forEach(twoItem=>{
          if(twoItem.inventoryList.length > 0) {
            twoItem.inventoryList.forEach(threeItem=>{
              // 选择的 并且是筒仓的
              if(threeItem.gicWarehouseType == '20' && threeItem.cherk) {
                cherkNum += 1
              }
            })
          }
        })
      }
      if(cherkNum > 0 && this.showTIP && this.smallLoanSwitch) {
        this.orderDetial[firstIndex].openCloseBtn = true
        this.orderDetial[firstIndex].cherkStatus = true
        this.$forceUpdate()
      } else {
        this.orderDetial[firstIndex].openCloseBtn = false
        this.orderDetial[firstIndex].cherkStatus = false
        this.$forceUpdate()
      }
        // this.$forceUpdate()

    },
    loadMore(){
      this.pageLoading = true;
      this.page.pageNo++;
      this.getListDeatil()
    },
    async getListDeatil() {
      let data  = {
        "terms": {
          "status": "po_submitted"
        },
        "page":this.page
      }
      this.pageLoading = true;
      getList(data).then(res => {

        if (res && res.data) {
          let realDate = JSON.parse(res.data);

          if (realDate && realDate.data && realDate.data.length > 0) {
            // 如果获取的数据为0  当前页数不为1
            if (realDate.data.length == 0 && this.page.pageNo != 1) {
              this.noMore = true;
              this.loadMores = false;
              //没有更多数据
            } else if (realDate.data.length != 0 && this.page.pageNo != 1) {
              this.orderDetial = this.orderDetial.concat(realDate.data);
              console.log('快速审核订单数据', this.orderDetial)
              this.$forceUpdate();
            } else if (realDate.data.length != 0 && this.page.pageNo == 1) {
              this.orderDetial = realDate.data;
              console.log('快速审核订单数据', this.orderDetial)
              this.NoDate = false;
              this.noMore = false;
              this.loadMores = true;

            } else if (realDate.data.length == 0 && this.page.pageNo == 1) {
              this.NoDate = true;
              this.noMore = false;
              this.loadMores = false;
            }

            if (realDate.data && realDate.data.length != 0 && this.page.pageNo * this.page.pageSize >= realDate.page.totalCount) {
              this.noMore = true;
              this.loadMores = false;
            }

            this.orderDetial.forEach(item => {
              // 出库面积
              item.chukuVolume = 0
              // 待出库数量
              item.daichuku = 0
              // 出库总数
              item.chukuTotal = 0
              item.shenheIsLoading = false
              item.checkAll = false
              item.cherkStatus = false
              item.openCloseBtn = false
              item.salesOrderItem.forEach(twoItem=>{
                let gicWarehouseType = ''
                if(this.shareTypezong == 'Y') {
                  let shareData = {
                    orgId:twoItem.orgCode ,
                    matklId:twoItem.materialGroupCode
                  }
                   queryShareFlag(shareData).then(res => {
                    this.shareType = res.data.data
                    if(res.data.data == 'Y') {
                      gicWarehouseType = '20'
                    } else {
                      gicWarehouseType = ''
                    }
                  })
                }
                twoItem.keyong = 0
                twoItem.bencichuku = 0
                if(gicWarehouseType == '20') {
                  twoItem.isshowZY = true
                } else {
                  twoItem.isshowZY = false
                }
                twoItem.isshowGX = true

                item.daichuku  += Number(twoItem.backnowledgedQty)
                twoItem.searchLength = twoItem.inventoryList.length
                twoItem.inventoryList.forEach(item3=>{
                  item3.qty1 = 0
                  item3.cherk = false
                  twoItem.keyong += Number(item3.bigQty);
                })
              })
            });
          }else {
            this.NoDate = true
          }

          this.NeworderDetail =   JSON.parse(JSON.stringify(realDate.data))
          if (this.NeworderDetail.length > 0) {
            this.NeworderDetail.forEach(item => {
              // 出库面积
              item.chukuVolume = 0
              // 待出库数量
              item.daichuku = 0
              // 出库总数
              item.chukuTotal = 0
              item.shenheIsLoading = false
              item.salesOrderItem.forEach(twoItem=>{
                twoItem.keyong = 0
                twoItem.bencichuku = 0
                item.daichuku  += Number(twoItem.backnowledgedQty)
                twoItem.searchLength = twoItem.inventoryList.length
                twoItem.inventoryList.forEach(item3=>{
                  item3.qty1 = 0
                  item3.cherk = false
                  twoItem.keyong += Number(item3.bigQty);
                })
              })
            });
          }
        } else {
          // 如果不返回数据 则展示没有数据的样式
          this.NoDate = true;
        }
        this.pageLoading = false;
      });
    }
  }
};